import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import SEO from '../components/SEO'

const HomePage = ({ data }) => {
  const { title, metaDescription, body } = data.contentfulHome
  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
      />
      <Container>
        <PageBody body={body} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query HomePage {
    contentfulHome(slug: { eq: "home" }) {
      title
      metaDescription {
        internal {
          content
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default HomePage
